@font-face {
  font-family: "loew-extrabold-italic";
  src: url("../../fonts/Loew-ExtraBoldItalic.otf") format("woff"),
  url("../../fonts/Loew-ExtraBoldItalic.otf") format("opentype"),
  url("../../fonts/Loew-ExtraBoldItalic.otf") format("truetype");
}

@font-face {
  font-family: "loew-bold";
  src: url("../../fonts/Loew-Bold.otf") format("woff"),
  url("../../fonts/Loew-Bold.otf") format("opentype"),
  url("../../fonts/Loew-Bold.otf") format("truetype");
}

@font-face {
  font-family: "loew-extrabold";
  src: url("../../fonts/Loew-ExtraBold.otf") format("woff"),
  url("../../fonts/Loew-ExtraBold.otf") format("opentype"),
  url("../../fonts/Loew-ExtraBold.otf") format("truetype");
}

@font-face {
  font-family: "loew-bold-italic";
  src: url("../../fonts/Loew-BoldItalic.otf") format("woff"),
  url("../../fonts/Loew-BoldItalic.otf") format("opentype"),
  url("../../fonts/Loew-BoldItalic.otf") format("truetype");
}

@font-face {
  font-family: "loew-heavy";
  src: url("../../fonts/Loew-Heavy.otf") format("woff"),
  url("../../fonts/Loew-Heavy.otf") format("opentype"),
  url("../../fonts/Loew-Heavy.otf") format("truetype");
}

@font-face {
  font-family: "loew-regular";
  src: url("../../fonts/Loew-Regular.otf") format("woff"),
  url("../../fonts/Loew-Regular.otf") format("opentype"),
  url("../../fonts/Loew-Regular.otf") format("truetype");
}

@font-face {
  font-family: "loew-thin";
  src: url("../../fonts/Loew-Thin.otf") format("woff"),
  url("../../fonts/Loew-Thin.otf") format("opentype"),
  url("../../fonts/Loew-Thin.otf") format("truetype");
}


$font-heavy: "loew-heavy";
$font-bold: "loew-bold";
$font-thin: "loew-thin";
$font-regular: "loew-regular";
$font-exbold: "loew-extrabold";
