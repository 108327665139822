@import "~bootstrap";
@import "./components/fonts";
@import "~slick-carousel/slick/slick";
@import "~select2";

//bootstrap changes
@media(min-width:1680px) {
  .container {
    max-width: 1440px;
  }
}

$red: #E52321;

.select2-container--default .select2-selection--single {
  border: none;
  border-bottom: 1px solid #aaa;
  border-radius: 0;
}

h1 {
  font-size: 72px;
  margin-bottom: 50px;
  font-family: $font-heavy;
  line-height: 1.1;
  @include media-breakpoint-down(lg) {
    font-size: 42px;
    margin-bottom: 25px;
  }
  @include media-breakpoint-down(md) {
    font-size: 36px;
    margin-bottom: 25px;
  }
  @include media-breakpoint-down(xs) {
    font-size: 27px;
    margin-bottom: 25px;
  }
}

h2 {
  font-size: 42px;
  margin-bottom: 2rem;
  font-family: $font-heavy;
  line-height: 1.1;
  @include media-breakpoint-down(lg) {
    font-size: 36px;
    margin-bottom: 18px;
  }
  @include media-breakpoint-down(md) {
    font-size: 24px;
    margin-bottom: 18px;
  }
}

h3 {
  font-size: 26px;
  margin-bottom: 2rem;
  font-family: $font-heavy;
  line-height: 1.1;
  @include media-breakpoint-down(sm) {
    font-size: 22px;
    margin-bottom: 18px;
  }
}

h4 {
  font-size: 28px;
  margin-bottom: 20px;
  font-family: $font-heavy;
  line-height: 1.1;
  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }
}

a {
  color: #333;
  font-family: $font-exbold;

  &:hover {
    color: #333;
  }
}

strong {
  font-family: $font-exbold;
}

img {
  max-width: 100%;
}

.fc-grey {
  color: #8A8A8A;
}

.float-s {
  padding:100px 0;
  @include media-breakpoint-down(sm) {
    padding:50px 0;
  }
}

#photodropdown {
  border: 1px solid #333;
  //padding: 5rem 1rem;
  font-size: 16px;
  color: #333;
  text-align: left;
  //@include media-breakpoint-down(lg) {
  //  padding: 30px 1rem;
  //}

  .commit-placeholder {
    position: relative;
    padding-left: 55px;
    cursor: pointer;

    .icon-plus {
      width: 42px;
      height: 42px;
      background: url('../images/icons/ico-plus.svg') center center no-repeat #333;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }

    span {
      font-family: $font-bold;
      font-size: 32px;
      display: block;
      @include media-breakpoint-down(md) {
        font-size: 22px;
      }
    }
  }
}

.button {
  padding: 1rem 2rem;
  font-size: 36px;
  font-family: $font-bold;
  color: #333;
  transition: .25s all;
  display: inline-block;
  cursor: pointer;
  @include media-breakpoint-down(sm) {
    font-size: 22px;
  }

  &:hover {
    text-decoration: none;
  }

  &-black {
    background: #333;
    color: white;
    border: 1px solid #333;

    &-s20 {
      padding: 0.5rem 1rem;
      font-size: 20px;
    }

    &:hover {
      background: transparent;
      color: #333;
    }
  }

  &-bordered {
    border: 2px solid #333;
    &-white {
      border-color: white;
      color: white;

      &:hover {
        color: #333;
        background: white;
        text-decoration: none;
      }
    }
    &:hover {
      background: #333;
      color: white;
    }
    &-grey {
      background: #333;
      border: 2px solid white;
      color:white;
      &:hover {
        background:transparent;
      }
    }
  }

  &-text {
    padding: 0;
    font-size: 20px;
    font-family: $font-bold;
  }

  &-small {
    font-size: 13px;
  }
}

.h-separ {
  border-bottom: 1px solid #AFAFAF;
  width: 100%;
}

.cd-inputs {
  .form-control {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #333;
    border-radius: 0;
    font-size: 16px;
    padding: 10px 15px;

    &::placeholder {
      color: #AFAFAF;
    }

    &.nice-select {
      span.current {
        position: relative;
        bottom: 13px;
      }
    }
  }

  .c-check {
    position: relative;
    padding-left: 25px;
    cursor: pointer;

    span {
      &::before {
        display: block;
        content: '';
        width: 15px;
        height: 15px;
        border: 1px solid #333;
        transition: .25s all ease;
        position: absolute;
        left: 0;
        bottom: 3px;
      }
    }

    input {
      display: none;
    }

    input:checked + span::before {
      background: #333;
    }
  }

  &-rects {
    .form-control {
      border: 1px solid #333;
      margin-top: 30px;

      &.nice-select {
        line-height: 1.5;
        height: auto;

        span.current {
          bottom: auto;
        }
      }
    }
  }

}

.order-info {
  font-size: 18px;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @include media-breakpoint-down(sm) {
    align-items: center;
    text-align: center;
  }

  ul {
    margin: 15px 0;
    padding: 0;
    list-style: none;

    &.equip {
      color: #AFAFAF;
      margin-bottom: 0;
    }

    &.equip-extra {
      color: #AFAFAF;
      margin-top: 0;
    }
  }

  .h-separ {
    max-width: 500px;
  }
}

.title {
  &-small {
    font-size: 37px;
    margin-bottom: 10px;
  }

  &-padding {
    padding-top: 7rem;
    @include media-breakpoint-down(sm) {
      padding-top: 150px;
    }
  }
}

.n-mb-2 {
  margin-bottom: -20px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
  }
}

.n-mb-5 {
  margin-bottom: -50px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
  }
}

.n-mt-5 {
  margin-top: -50px;
  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }
}

.mt-10 {
  margin-top: 100px;
  @include media-breakpoint-down(sm) {
    margin-top: 50px;
  }
}

.mb-10 {
  margin-bottom: 100px;
}

.mb-15 {
  margin-bottom: 150px;
}

.s-56 {
  font-size: 42px !important;
  line-height: 1.5 !important;
  @include media-breakpoint-down(md) {
    font-size: 36px !important;
    line-height: 1.3 !important;
  }
  @include media-breakpoint-down(sm) {
    font-size: 28px !important;
    line-height: 1.3 !important;
  }
}

.s-36 {
  font-size: 36px !important;
  line-height: 43px !important;
  @include media-breakpoint-down(lg) {
    font-size: 26px !important;
    line-height: 1.3 !important;
  }
  @include media-breakpoint-down(md) {
    font-size: 24px !important;
    line-height: 1.3 !important;
  }
  @include media-breakpoint-down(sm) {
    font-size: 20px !important;
    line-height: 1.3 !important;
  }
}

.s-28 {
  font-size: 28px !important;
  line-height: 42px !important;
  @include media-breakpoint-down(sm) {
    font-size: 22px !important;
    line-height: 1.3 !important;
  }
}

.s-22 {
  font-size: 22px !important;
  line-height: 32px !important;
  @include media-breakpoint-down(sm) {
    font-size: 18px !important;
    line-height: 1.3 !important;
  }
}

.s-18 {
  font-size: 18px !important;
  line-height: 27px !important;
  @include media-breakpoint-down(sm) {
    font-size: 16px !important;
    line-height: 1.3 !important;
  }
}

.s-small {
  font-size: .8rem !important;
}

.s-mobile-small {
  @include media-breakpoint-down(sm) {
    font-size: .8rem !important;
  }
}

.embed-responsive-3by2 {
  &::before {
    padding-top: 60%;
    display: block;
    content: '';
  }

  .embed-responsive-item {
    object-fit: cover;
  }
}

.embed-responsive-4by5 {
  &::before {
    padding-top: 125%;
    display: block;
    content: '';
  }

  .embed-responsive-item {
    object-fit: cover;
  }
}

body {
  font-family: "loew-regular", sans-serif;
  position: relative;
  color: #333;
  background: #EDEDED;

  &::before {
    position: absolute;
    width: 100%;
    height: 6px;
    background: $red;
    top: 0;
    left: 0;
    content: '';
    display: block;
    z-index: 15;
  }

  &.home {
    &::before {
      display: none;
    }

    header.header_main.fixed {
      background: rgba(0,0,0,0.25);
    }

    header.header_main .header_menuholder #nav-icon span {
      background: white;
    }

    .header_logo {
      background-image: url('../images/caster_logo.svg');
    }
  }

  &.white-nav {
    background: white;

    header.header_main.fixed {
      background: rgba(0,0,0,0.25);
    }

    .header_logo {
      background-image: url('../images/caster_logo.svg');
    }

    header.header_main .header_menuholder #nav-icon span {
      background: white;
    }
  }
}

.arrow_down {
  font-size: 15px;
  font-family: $font-bold;
  position: absolute;
  bottom: 5rem;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;

  &:hover {
    color: white;
  }

  &::after {
    top: 160%;
    width: 54px;
    height: 27px;
    content: '';
    display: block;
    position: absolute;
    background: url('../images/arrow_d.svg') no-repeat center center;
    background-size: contain;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.header {
  &_main {
    position: absolute;
    z-index: 10;
    transition: all .2s ease;
    background: rgba(0,0,0,0);

    * {
      transition: all .2s ease;
    }

    &.active {
      aside.nav {
        left: 0;
        transform: rotate3d(0, 0, 0, 0deg);
      }

      .header_row {
        position: fixed;

        .header_menuholder {
          #nav-icon {
            span {
              background: #333;
            }

            span:nth-child(1), span:nth-child(6) {
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
            }

            span:nth-child(2), span:nth-child(5) {
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(-45deg);
            }

            span:nth-child(1) {
              left: 5px;
              top: 7px;
            }

            span:nth-child(2) {
              left: calc(50% - 5px);
              top: 7px;
            }

            span:nth-child(3) {
              left: -50%;
              opacity: 0;
            }

            span:nth-child(4) {
              left: 100%;
              opacity: 0;
            }

            span:nth-child(5) {
              left: 5px;
              top: 20px;
            }

            span:nth-child(6) {
              left: calc(50% - 5px);
              top: 20px;
            }
          }
        }
      }

      .header_logo {
        background-image: url('../images/caster_logo_black.svg');
      }
    }

    &.fixed {
      position: fixed;
      background: white;
      .header_row {
        padding: 1rem;
        @media (max-width: 1440px) {
          padding: 27px;
        }
        .header_logo {
          display: none;
        }
        #nav-icon {
          margin-top: 0;
        }
      }
    }
  }

  &_row {
    padding: 3rem;
    position: relative;
    z-index: 10;
    @include media-breakpoint-down(md) {
      padding: 30px;
    }
    @include media-breakpoint-down(sm) {
      padding: 20px;
    }
  }

  &_logo {
    width: 6rem;
    height: 3rem;
    background: url('../images/caster_logo_black.svg') no-repeat center center;
    background-size: contain;
    display: block;
  }

  &_menuholder {
    cursor: pointer;
    position: relative;

    #nav-icon {
      width: 45px;
      height: 30px;
      position: relative;
      margin-top: 30px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 50%;
        background: #333;
        opacity: 1;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
      }

      span:nth-child(even) {
        left: 50%;
        border-radius: 0;
      }

      span:nth-child(odd) {
        left: 0;
        border-radius: 0;
      }

      span:nth-child(1), span:nth-child(2) {
        top: 0;
      }

      span:nth-child(3), span:nth-child(4) {
        top: 12px;
      }

      span:nth-child(5), span:nth-child(6) {
        top: 24px;
      }
    }
  }
}

.headline {
  font-size: 18px;
  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }

  p {
    @include media-breakpoint-down(lg) {
      font-size: 22px;
    }
    @include media-breakpoint-down(md) {
      font-size: 20px;
    }
  }

  &_big {
    p {
      font-size: 28px;
      line-height: 1.4;
      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }
    }
  }
}

.main-body {
  position: relative;
  min-height: 100vh;
  padding-top: 10rem;
  .color-white {
    color:white;
    a {
      color:white;
    }
    @include media-breakpoint-down(md) {
      color:rgb(51,51,51);
      a {
        color:rgb(51,51,51);
      }
    }
  }
  &.dirs {
    padding-top: 0;

    .dirs-option {
      height: 100vh;
      position: relative;
      color: white;
      text-align: center;
      background: #333333 top center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 150px 30px 150px;
      @include media-breakpoint-down(xs) {
        padding: 30px 30px 30px;
        height: 40vh;
      }
      h2 {
        @include media-breakpoint-down(xs) {
          font-size: 20px;
        }
      }

      &-adult {
        background: url('../images/panska.jpg') no-repeat center center;
        background-size: cover;
        justify-content: flex-end;
        @include media-breakpoint-down(xs) {
          height: 30vh;
        }

        &:hover {
          cursor: pointer;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.1);
          }

          a {
            background:transparent;
            text-decoration: none;
          }
        }
      }

      &-child {
        background: url('../images/detska.jpg') no-repeat center center;
        background-size: cover;
        justify-content: flex-end;
        @include media-breakpoint-down(xs) {
          height: 30vh;
        }

        &:hover {
          cursor: pointer;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.1);
          }

          a {
            background:transparent;
            text-decoration: none;
          }
        }

        &-detail {
          @include media-breakpoint-down(xs) {
            padding: 100px 30px 100px;
            height: 100vh;
          }
        }
      }

      a {
        color: white;
        display: inline-block;
        padding: 20px;
        align-items: flex-end;
        justify-content: center;
        flex-wrap: wrap;
        background: #333;
        border: 2px solid white;

        &:hover {
          background:transparent;
          text-decoration: none;
        }


        span {
          font-weight: normal;
          display: block;
          width: 100%;
        }
      }
    }
  }

  &.home {
    background: url('../images/homeBg.jpg') no-repeat center center;
    background-size: cover;
    color: white;
    position: relative;
    z-index: 1;
    overflow: hidden;

    #bg-video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: 100%;
      transform: translate(-50%, -50%);
      @media (min-aspect-ratio: 16/9) {
        width: 100%;
        height: auto;
      }
      @media (pointer: none) {
        display: none;
      }
    }

    .jumb {
      text-shadow: 4px 4px 40px rgba(0, 0, 0, 0.2);
      position: absolute;
      left: 250px;
      top: auto;
      bottom:25%;
      pointer-events: none;
      @include media-breakpoint-down(lg) {
        top: 25%;
      }
      @include media-breakpoint-down(md) {
        left: 150px;
      }
      @include media-breakpoint-down(sm) {
        left: 100px;
      }
      @include media-breakpoint-down(xs) {
        left: 15px;
      }

      .smweb-intext.cke_editable{
        pointer-events: all;
      }

      h1 {
        font-weight: normal;
        font-size: 72px;
        @media (max-width: 1440px) {
          font-size: 52px;
        }
        @include media-breakpoint-down(sm) {
          font-size: 48px;
        }
        @include media-breakpoint-down(xs) {
          font-size: 29px;
        }
      }

      p {
        font-size: 36px;
        text-shadow: 4px 4px 40px rgba(0, 0, 0, 0.2);
        @media (max-width: 1440px) {
          font-size: 26px;
        }
        @include media-breakpoint-down(xs) {
          font-size: 20px;
        }
      }
    }
  }

  &.half-body {
    @include media-breakpoint-down(md) {
      min-height: auto;
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 0;
  }
}

.order-step {
  min-height: auto;
  @include media-breakpoint-up(lg) {
    padding-bottom: 75px;
  }
  .half-body-image.static {
    @include media-breakpoint-up(lg) {
      height: 100%;
    }
  }
}

.half-body {
  position: relative;

  &-righten {
    .half-body-image {
      left: auto;
      right: 0;
      @include media-breakpoint-down(md) {
        width: 100%;
        height: 200px;
        position: relative;
      }
    }

    .half-body-content {
      padding-left: 30px;
      padding-right: 40px;
      @include media-breakpoint-down(md) {
        padding: 60px 10px 10px;
      }
      @include media-breakpoint-down(sm) {
        padding: 40px 25px;
      }
    }
  }

  &-image {
    background-position: center center;
    background-size: cover;
    width: 50vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    &.blacked {
      &::after {
        position: fixed;
        width: 50vw;
        height: 100vh;
        left: 0;
        top: 0;
        content: '';
        display: block;
        background: rgb(51, 51, 51);
        opacity: 0.8;
        @include media-breakpoint-down(md) {
          width: 100%;
          height: 200px;
          position: absolute;
        }
      }
    }

    &.static {
      position: absolute;
      @include media-breakpoint-down(md) {
        position: relative;
      }
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      height: 200px;
      position: relative;
    }
  }

  &-content {
    padding-left: 40px;
    padding-top: 100px;
    @include media-breakpoint-down(md) {
      padding: 60px 10px 10px;
    }
    @include media-breakpoint-down(sm) {
      padding: 40px 25px;
    }
  }
}

aside.nav {
  //left: -500px;
  transform: rotate3d(0, 1, 0, 90deg);
  transform-origin: left center;
  background: white;
  position: fixed;
  top: 0;
  bottom: 0;
  padding: 10rem 3rem 1rem;
  transition: 0.3s all ease-in-out;
  display: flex;
  flex-direction: column;
  @media (max-width: 1440px) {
    padding: 90px 30px 0;
  }
  @include media-breakpoint-down(sm) {
    padding: 90px 20px 0;
    box-shadow: 0 0 38px 0 rgba(0, 0, 0, 0.2);
  }

  ul.sm_menu {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 2rem;
    width: calc(100% - 60px);
    @include media-breakpoint-down(sm) {
      position: relative;
      bottom: auto;
      margin: 15px 0;
    }

    li {
      a {
        color: #7C7C7C;
        font-family: "loew-thin", sans-serif;
        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
      }

      &.socials {
        display: flex;
        justify-content: space-around;
        padding-top: 1rem;
        a {
          transform: scale(0.5);
        }
      }
    }
  }

  .navs {
    width: 15rem;

    .nav_main {
      padding-bottom: 2.5rem;
      @include media-breakpoint-down(md) {
        padding-bottom: 20px;
      }
      position: relative;

      /*&::after {
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #707070;
        content: '';
        display: block;
        bottom: 0;
      }*/

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {

          a {
            display: inline-block;
            color: #333;
            font-size: 26px;
            padding: 0.4rem 0;
            @include media-breakpoint-down(xl) {
              font-size: 18px;
              padding: 5px 0;
            }
          }
        }
      }
    }

    .nav_socials {
      padding-top: 2.5rem;
      @include media-breakpoint-down(md) {
        padding-top: 20px;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          padding: 0.5rem 0;
          @include media-breakpoint-down(md) {
            padding: 4px 0;
          }

          a {
            color: #7C7C7C;
            font-size: 18px;
            @include media-breakpoint-down(md) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.floating-bed {
  position: fixed;
  width: 50vw;
  height:100%;
  right: 0;
  top: -100px;
  overflow: hidden;
  @include media-breakpoint-down(md) {
    position: relative;
    top: -40px;
    width: 100%;
    left: -80px;
  }
}

.list-margin {
  p {
    margin-bottom: 2rem;
  }
}

.our-work {
  background: white;
  padding: 100px 0;
  position: relative;
  @include media-breakpoint-down(md) {
    padding-top: 150px;
  }
  @include media-breakpoint-down(sm) {
    padding-bottom: 50px;
  }

  .image {
    position: relative;

    img {
      max-width: 105%;
      @media (max-width: 1500px) {
        max-width: 100%;
      }
    }
  }

  .title {
    padding:0 40px;
    @include media-breakpoint-down(sm) {
      padding:0 15px;
    }

    .bestseller {
      display: inline-block;
      padding: 7px 10px;
      background: rgba(167, 25, 24, 0.85);
      color: white;
      font-weight: bold;
      margin: 0;
      margin-bottom: 1rem;
    }

    .novinka {
      display: inline-block;
      padding: 7px 10px;
      background: rgba(255, 208, 36, 0.85);
      color: black;
      font-weight: bold;
      margin: 0;
      margin-bottom: 1rem;
    }
  }

  .order-wrap {
    &:nth-child(even) {
      .image {
        order: 2;
        position: relative;
        z-index: 2;

        img {
          position: relative;
          right: 5%;
          @include media-breakpoint-down(sm) {
            right: auto;
          }
        }

        @include media-breakpoint-down(sm) {
          order: 0;
        }
      }
    }
  }
}

.carousel-benefits {
  padding: 5rem 0;
  background: white;
  position: relative;
  @include media-breakpoint-down(sm) {
    padding: 35px 0;
  }
  .carousel-holder {
    @include media-breakpoint-down(sm) {
      padding:0 30px;
    }
  }
  .car-item {
    position: relative;
    padding-left: 3.5rem;
    @include media-breakpoint-down(xs) {
      padding:0;
    }
    p {
      padding-right: 30px;
    }
    img {
      position: absolute;
      top: 10px;
      left: 0;
      @include media-breakpoint-down(sm) {
        position: relative;
        top:0;
        margin-bottom: 10px;
      }
    }
  }

  .car-arrow-prev, .car-arrow-next {
    width: 27px;
    height: 54px;
    background: url('../images/icons/ico_arrow-right.svg') no-repeat center center;
    display: block;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  .car-arrow-next {
    left: 100%;

    @media (min-width: 1200px) and (max-width: 1500px) {
      transform: translate(-100%, -50%);
    }
    @include media-breakpoint-down(sm) {
      left: auto;
      right: 5px;
      transform: scale(0.8, 0.8);
    }
  }

  .car-arrow-prev {
    transform: translate(0, -50%) rotate(180deg);
    right: 100%;

    @media (min-width: 1200px) and (max-width: 1500px) {
      transform: translate(100%, -50%) rotate(180deg);
    }
    @include media-breakpoint-down(sm) {
      left: 5px;
      right: auto;
      transform: scale(0.5, 0.5) rotate(180deg);
    }
  }
}

.product-box {
  margin: 40px 0;
  transition: .25s all ease-in-out;
  display: flex;
  @include media-breakpoint-up(xl) {
    margin: 70px 0;
  }
  @include media-breakpoint-down(sm) {
    margin: 35px 0;
  }

  &-content {
    width: 70%;

    .title-small {
      @include media-breakpoint-down(sm) {
        font-size: 24px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 20px;
      }
    }
  }

  &-wrapped {
    flex-direction: column;
  }

  &-image {
    position: relative;
  }

  &-price {
    font-size: 20px;
    margin: 10px 0;
  }

  &-btn {
    a.button-text {
      @include media-breakpoint-down(xs) {
        font-size: 16px;
      }
    }
  }

  &.active {
    min-height: 300px;
    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
    }

    .product-box-pic {
      width: 300px;
      height: 300px;
    }

    .product-box-content {
      @include media-breakpoint-down(xs) {
        order: 1;
      }
    }
  }

  &-pic {
    //position: absolute;
    left: calc(100% - 90px);
    top: 0;
    width: 90px;
    height: 90px;
    transition: .25s all;
    background-size: cover;
    background-position: center center;
  }

  &-info {
    p {
      font-size: 14px;
      color: #7D7D7D;
    }

    &-toggle {
      .toggle-content {
        display: none;
      }

      .toggle-show {
        display: block;
        font-size: 14px;
        color: #7D7D7D;
        cursor: pointer;
      }
    }
  }
}

section.follow-us {
  margin-top: 100px;
  @include media-breakpoint-down(sm) {
    margin-top: 50px;
  }

  a {
    transition: .25s all;

    &:hover {
      opacity: 0.5;
    }
  }
}

.perex-box {
  border-left: 3px solid $red;
  font-size: 36px;
  font-family: $font-heavy;
  padding-left: 1rem;
  @include media-breakpoint-down(md) {
    font-size: 26px;
  }
}

.buy-it {
  height: 90vh;
  background: url('../images/buyit_bg.jpg') no-repeat center center #fcfcfc;
  background-size: cover;
  position: relative;
  padding: 5rem 0;
}

.red-link {
  background: $red;
  padding: 1rem 0;
  position: relative;
}

.zig-zag {
  position: relative;

  > div:nth-of-type(even) {
    position: relative;
    z-index: 2;

    div.embed-responsive {
      margin: 15px 0;
      @include media-breakpoint-up(lg) {
        margin: -60px 0;
      }
    }
  }
}

.control {
  .cntrl-arr {
    position: relative;
    padding-left: 45px;
    color: #333;

    &::before {
      width: 27px;
      height: 54px;
      background: url('../images/arrow-left.svg') no-repeat center center;
      background-size: contain;
      position: absolute;
      display: block;
      content: '';
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      @include media-breakpoint-down(sm) {
        transform: translate(0, -50%) scale(0.5, 0.5);
      }
    }

    &.next {
      padding-left: 0;
      padding-right: 45px;

      &::before {
        right: 0;
        left: auto;
        transform: translate(0, -50%) rotate(180deg);
        @include media-breakpoint-down(sm) {
          transform: translate(0, -50%) rotate(180deg) scale(0.5, 0.5);
        }
      }
    }
  }
}

.gallery-w-thumbs {
  position: relative;

  .main-gallery {
    width: calc(100% - 134px);
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    .slick-slide img {
      object-fit: contain;
    }

    .img-holder {
      img {
        object-fit: contain;
      }
    }
  }

  .gallery-thumbs {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 134px;
    @include media-breakpoint-down(sm) {
      width: 100%;
      transform: none;
      top: auto;
      right: auto;
      position: relative;
    }

    .thumb-container {
      position: relative;
    }

    .arr-down, .arr-up {
      width: 17px;
      height: 28px;
      background: url('../images/arr-down.svg') no-repeat center center;
      background-size: contain;
      display: block;
      position: absolute;
      top: 105%;
      left: 50%;
      transform: translate(-150%, 0);
      cursor: pointer;
      z-index: 10;
      @include media-breakpoint-down(sm) {
        top: 50%;
        transform: translate(0, -50%) rotate(90deg);
        background-image: url('../images/arr-down-w.svg');
        left: 10px;
      }
    }

    .arr-up {
      transform: translate(75%, 0) rotate(180deg);
      @include media-breakpoint-down(sm) {
        left: auto;
        right: 10px;
        transform: translate(0, -50%) rotate(-90deg);
      }
    }

    .thumb-item {
      width: 137px;
      height: 137px;
      background-size: cover;
      margin: 2px 0;
      @include media-breakpoint-down(sm) {
        height: 80px;
      }
    }
  }
}

.bed-config {
  .c-wrap {
    display: flex;
    margin: 10px 0;
    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
    }

    .button {
      @include media-breakpoint-down(xs) {
        margin: 5px 0;
      }
    }

    .par-col {
      width: 135px;
      text-align: right;
      padding-right: 10px;
      @include media-breakpoint-down(xs) {
        width: 100%;
        min-width: auto;
        display: block;
        text-align: left;
        margin-bottom: 10px;
        font-weight: bolder;
        font-family: "loew-extrabold";
      }
    }

    .val-col {
      width: calc(100% - 135px);
      padding-left: 10px;
      @include media-breakpoint-down(xs) {
        padding-left: 0;
        width:100%;
      }

      .form-control {
        font-family: $font-bold;
      }
    }
  }

  .c-radio {
    display: flex;
    justify-content: space-around;
    position: relative;
    flex-wrap: wrap;
    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
    }

    label {
      position: relative;
      padding-left: 25px;
      cursor: pointer;
      width: auto;
      margin: 0 10px;
      @include media-breakpoint-down(xs) {
        margin: 0 10px 0 0;
      }

      &.disabled {
        opacity: .5;
      }

      .bed-icon {
        width: 36px;
        height: 26px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;

        &-left {
          background-image: url('../images/icons/bed-left.svg');
        }

        &-space {
          background-image: url('../images/icons/bed-space.svg');
        }

        &-right {
          background-image: url('../images/icons/bed-right.svg');
        }
      }

      span.orient-rad::before {
        display: block;
        content: '';
        width: 15px;
        height: 15px;
        border: 1px solid #333;
        transition: .25s all ease;
        position: absolute;
        left: 0;
        bottom: 3px;
      }

      input {
        display: none;
      }

      input:checked + span.orient-rad::before {
        background: #333;
      }
    }
  }

  .additions {
  }
}

.tooltip {
  &.show {
    opacity: 1 !important;
  }

  .tooltip-inner {
    border-radius: 0;
    background-color: #d3d4d6;
    color: #000;
    padding: 10px;
  }

  .arrow::before {
    border-top-color: #d3d4d6;
  }
}


.decor-gallery {
  position: relative;
  width: 100%;
  background: #C9C9C9;
  padding: 10px 30px;

  .decor-arr {
    width: 7px;
    height: 14px;
    background: url('../images/decor-arrow.svg') no-repeat center center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);

    &.next {
      left: auto;
      right: 10px;
      transform: translate(0, -50%) rotate(180deg);
    }
  }

  .img {
    margin: 0 5px;
    max-width: 100%;
    min-height: 74px;
    position: relative;
    cursor: pointer;

    &.selected::after {
      content: "✓";
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      background-color: rgba(255,255,255, 0.3);
      font-size: 3rem;
    }
  }
}

.mob-pt {
  @include media-breakpoint-down(md) {
    padding-top: 150px;
    min-width: auto;
  }
  &-halfed {
    @include media-breakpoint-down(md) {
      padding-top: 40px;
    }
  }
}

.grey-bg {
  position: relative;
  background: #EDEDED;
  padding-bottom: 50px;
}

.uppy-Dashboard-inner {
  min-height: auto !important;
}

.children-beds {
  .bed {
    transition: all .2s ease-out;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
    img {
      border: 1px solid #EDEDED;
    }
    .zoom {
      position: absolute;
      right: 15px;
      top: 0;
      z-index: 1;
    }
    &.selected::after {
      content: "✓";
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      background-color: rgba(255,255,255, 0.3);
      font-size: 3rem;
    }
  }
  .unknown {
    & > div {
      background: white;
    }
    &::after {
      justify-content: flex-start !important;
      left: 30px !important;
    }
  }
}

#askform {
  h3 {
    font-family: "loew-regular", sans-serif;
    font-size: 2.5rem;
    @include media-breakpoint-down(lg) {
      font-size: 1.9rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 1.5rem;
    }
    @include media-breakpoint-down(xs) {
      font-size: 20px;
    }
  }
}

.form-check-input {
  margin-top: 0.45rem;
}

.coutry-select2 {
  .select2 {
    border: 1px solid #333;
    margin-top: 30px;
    padding: 4px 15px;
    width: 100% !important;

    .select2-selection {
      border: none;
    }
    .select2-selection__arrow {
      height: 34px;
    }
  }
}

.masiv-modal .modal-body, .lamino-modal .modal-body {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: wrap;

  .img {
    display: flex;
    flex: 0 1 33%;
    min-height: 200px;
    position: relative;

    span {
      position: absolute;
      width: 100%;
      top: 10%;
      color: white;
      text-align: center;
      text-shadow: #000000 1px 0 10px;
    }

    &:hover {
      cursor: pointer;
    }

    &.selected::after {
      content: "✓";
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      background-color: rgba(255,255,255, 0.3);
      font-size: 3rem;
    }
  }
}

.payment {
  max-width: 500px;
  width: 100%;

  .payment-type {
    label {
      position: relative;
      padding-left: 25px;
      cursor: pointer;
      width: auto;
      margin: 0 10px;
      text-align: left;
      @include media-breakpoint-down(xs) {
        margin: 0 10px 0 0;
      }

      &.disabled {
        opacity: .5;
      }

      span.orient-rad::before {
        display: block;
        content: '';
        width: 15px;
        height: 15px;
        border: 1px solid #333;
        transition: .25s all ease;
        position: absolute;
        left: 0;
        //bottom: 3px;
        top: 50%;
        transform: translateY(-50%);
      }

      input {
        display: none;
      }

      input:checked + span.orient-rad::before {
        background: #333;
      }
    }
  }

  .payment-type {
    margin: 0 -10px;
  }

  .part-comment {
    background: #d3d4d6;
    font-size: 0.9rem;
    padding: 1rem;
    margin-top: 1.5rem;
    position: relative;
    display: none;
    text-align: left;

    &:after, &:before {
      bottom: 100%;
      right: 50px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #d3d4d6;
      border-width: 8px;
      margin-left: -8px;
    }
    &:before {
      border-color: rgba(0, 0, 0, 0);
      border-bottom-color: #d3d4d6;
      border-width: 14px;
      margin-left: -14px;
    }
  }

  .methods {
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
    margin-top: 2rem;
    margin-bottom: 1rem;

    label {
      flex-basis: 0;
      flex-grow: 1;
      margin: 0 10px;
      cursor: pointer;

      input {
        display: none;
      }


      .wrapper {
        border: 1px solid #333;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1.5rem 1rem;
        height: 100%;
        transition: .25s all ease;

        p {
          text-align: center;
          margin: 0;
        }

        .icon-white {
          display: none;
        }

      }

      input:checked ~ .wrapper {
        background: #333;
        color: #fff;

        .icon-dark {
          display: none;
        }
        .icon-white {
          display: block;
        }
      }
    }
  }
}

.inspiration {
  max-height: 250px;
  margin-bottom: 25px;
  display: block;
  overflow: hidden;
}

.suppliers {
  img {
    max-height: 50px;
  }
}

a {
  &:hover {
    color:$red;
  }
}

.contact-text {
  p {
    font-size: 24px;
    margin-bottom: 10px;
  }
  small {
    font-size: 18px;
  }
}

.coutry-select2 {
  overflow: hidden;
}

#askform {
  .whats-need {
    padding-top: 30px;
    h3 {
      padding-left: 70px;
      font-size: 1.8rem;
      position: relative;
      margin-bottom: 4rem;
      @include media-breakpoint-down(md) {
        font-size: 20px;
      }
      &::before {
        position:absolute;
        width:40px;
        height:40px;
        background:no-repeat center center;
        background-size: contain;
        content: '';
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: block;
      }
      &.idea {
        &::before {
          background-image: url('../images/003-lamp.svg');
          @include media-breakpoint-down(md) {
            background-image: url('../images/003-lamp_b.svg');
          }
        }
      }
      &.foto {
        &::before {
          background-image: url('../images/002-plans.svg');
          @include media-breakpoint-down(md) {
            background-image: url('../images/002-plans_b.svg');
          }
        }
      }
      &.mat {
        &::before {
          background-image: url('../images/001-wood.svg');
          @include media-breakpoint-down(md) {
            background-image: url('../images/001-wood_b.svg');
          }
        }
      }
    }
  }
}

#konfigurator {
  .help-popup {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    transform: translateY(-100%);
    transition: all .5s cubic-bezier(0.34, 1.56, 0.64, 1);

    @include media-breakpoint-down(md) {
      position: fixed;
      z-index: 99;
      background-color: rgba(0, 0, 0, 0.8);
    }

    &.active {
      transform: translateY(0);
    }

    .help-popup-body {
      padding: 30px;
      text-align: center;
      h2 {
        font-size: 2rem;
        color: white;
      }
      p {
        color: white;
      }
      .button {
        font-size: 1.5rem;
      }
    }
  }
}

.realizace {

  a {
    display: block;
    position: relative;
    height: 100%;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background-color: rgba(0,0,0,.15);
      transition: all .2s ease;
    }

    &:hover {
      &::before {
        background-color: rgba(0,0,0,.3);
      }

      h4 {
        text-decoration: underline;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    h4 {
      color: white;
      position: absolute;
      left: 10%;
      top: 20%;
      max-width: 70%;
    }

    .bestseller {
      position: absolute;
      bottom: 10px;
      right: 0;
      padding: 7px 10px;
      background: rgba(167, 25, 24, 0.7);
      color: white;
      margin: 0;
    }

    .novinka {
      position: absolute;
      bottom: 10px;
      left: 0;
      padding: 7px 10px;
      background: rgba(255, 208, 36, 0.7);
      color: black;
      margin: 0;
    }
  }
}

.fixed-price {
  position: fixed;
  bottom: 0;
  right: 0;
  background: white;
  border-top: 1px solid black;
  width: 100%;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    width: 49%;
  }
}

.modal.discount {
  .modal-lg {
    max-width: 920px;
    .modal-content {
      background:url('../images/landingpage/modal-bg-red.jpg') no-repeat center right #A71918;
      background-size: auto 100%;
      color:white;
      padding:55px;
      border-radius: 0;
      position: relative;
      border:0;
      @include media-breakpoint-down(sm) {
        padding: 25px;
      }
      p.h {
        font-size: 32px;
        margin: 0;
        line-height: 1.25;
        @include media-breakpoint-down(sm) {
          font-size: 20px;
        }
      }
      p.s {
        margin: 30px 0 0;
        color: #FFD024;
        font-weight: bold;
      }
    }
  }
}
